import React from "react"

import Layout from "../components/layout"
import SeoMeta from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SeoMeta title="Not Found" />
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3>Whoops</h3>
          <p>Looks like our preschoolers ran off with that page.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
